<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
      size="lg"
      class="basic-modal-color"
    > 
    <CRow>      
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="collaborators"
            :fields="fieldsCollaborators"
            :dark="dark"
            style="width: 100%; font-size: 10px;"
          >
            <template #collaborator_name="{item}">
              <td class="text-center">{{item.collaborator_name}}</td>
            </template>
            <template #branch_state="{item}">
              <td class="text-center w50">
                {{item.branch_state}}        
              </td>
            </template>
            <template #pending="{item}">
              <td class="text-center w50">
                {{item.pending}}        
              </td>
            </template>
            <template #rejected="{item}">
              <td class="text-center w50">
                {{item.rejected}}        
              </td>
            </template>
            <template #never_completed="{item}">
              <td class="text-center w50">
                {{item.never_completed}}        
              </td>
            </template>            
          </CDataTable>        
        </CCol>
      </CRow>    
      <CRow>      
        <CCol md="12" class="table-support">
          <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="branchs"
              :fields="fields"
              :items-per-page="15"
              :dark="false"
              pagination
              style="width: 100%; font-size: 10px;"
              :table-filter="{label: 'Buscar: ', placeholder: '...'}"
            >
              <template #branch_code="{item}"><td class="text-center w50">{{item.branch_code}}</td></template>
              <template #branch_name="{item}"><td>{{item.branch_name}}</td></template>
              <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
              <template #visit_date="{item}">
                <td class="text-center w50">
                  {{item.visit_date | date}}
                </td>
              </template>
              <template #validation_date="{item}">
                <td class="text-center w50">
                  {{item.validation_date | date}}
                </td>
              </template>
              <template #status="{item}">
                <td class="text-center w50">
                  {{item.status}}
                </td>
              </template>             
            </CDataTable>
        </CCol>
      </CRow>      
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../../store';
import ws from '../../../services/graphs';

export default {
  name: 'BranchsKamsModal',
  props: {
    fields: {
      type: Array,
      default () {
        return [
          {key: 'branch_code', label: 'Determinante' ,_style:" width: 50px;"},
          {key: 'branch_name', label: 'Sucursal'},
          {key: 'customer_name', label: 'Marca'},
          {key: 'visit_date', label: 'Fecha Visita' ,_style:" width: 80px;"},
          {key: 'validation_date', label: 'Fecha Revision' ,_style:" width: 80px;"},
          {key: 'status', label: 'Estatus',_style:" width: 50px;"},
        ]
      }
    },
    fieldsCollaborators: {
      type: Array,
      default () {
        return [
          {key: 'collaborator_name', label: 'Promotor'},
          {key: 'branch_state', label: 'Ruta' ,_style:" width: 150px;"},
          {key: 'pending', label: 'Pendientes' ,_style:" width: 100px;"},
          {key: 'rejected', label: 'Rechazadas' ,_style:" width: 100px;"},
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  components: {
  },
  data () {
    return {
      showModal: false,
      title: "Detalle Promotor",
      color: "info",
      submitted: false,      
      branchs: [],
      collaborators: [],
    }
  },
  mounted: async function() {    
    
  },
  methods: {
    async showBranchsKamsModal (params) {
      this.loading();
        this.showModal = true;
        this.color = "info";
        this.title = 'Detalle Promotor';
        this.isEdit = false;
        this.submitted = false;
      
        let response = await ws.detailKamsSupports(params);
        if(response.type == "success"){
          this.branchs = response.data.branchs;
          this.collaborators = response.data.collaborator;
        }
      this.loaded();

    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
    },
    getEmptyForm () {
      return {
          id: 0,
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
}
</script>