import axios from 'axios'
import store from '../store'

export default {
    getMonths: () => axios.post('/admin/dashboard/months'),
    //Servicios Generales
    getCustomers: (params) => axios.post('/admin/dashboard/customers',params),
    getCollaborators: (params) => axios.post('/admin/dashboard/collaborators',params),
    getKams: () => axios.post('/admin/dashboard/kams'),
    getKamsSupports: () => axios.post('/admin/dashboard/kams-supports'),
    getKamsBoss: () => axios.post('/admin/dashboard/kams-boss'),
    getGenerics: () => axios.post('/admin/dashboard/generics'),

    //Servicios Resumen General mes Actual default
    getMetrics: (params) => axios.post('/admin/dashboard/metrics',params),
    getGraphs: (params) => axios.post('/admin/dashboard/graphs1',params),

    //kams (soporte)
    getMetricsKamsSupports: (params) => axios.post('/admin/dashboard/metrics/kams-supports',params),
    getTableKamsSupports: (params) => axios.post('/admin/dashboard/graphs1/kams-supports',params),
    detailKamsSupports: (params) => axios.post('/admin/dashboard/graphs1/kams-supports-detail',params),
    downloadExcelKamsSupports: (filters) => axios.post('/admin/dashboard/download-excel/kams-supports',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    updateCollaboratorSalaryExtra: (params) => axios.post('/admin/dashboard/graphs1/kams-supports/collaboratory-salary/update',params),

    //kams (jefe)
    getMetricsKamsBoss: (params) => axios.post('/admin/dashboard/metrics/kams-boss',params),
    getTableKamsBoss: (params) => axios.post('/admin/dashboard/graphs1/kams-boss',params),
    detailKamsBoss: (params) => axios.post('/admin/dashboard/graphs1/kams-boss-detail',params),    
    downloadExcelKamsBoss: (filters) => axios.post('/admin/dashboard/download-excel/kams-boss',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),

    //Rezagados
    getGenericsBranches: (params) => axios.post('/admin/dashboard/generics-branches',params),
    //Universo kams
    getUniverseKams: (params) => axios.post('/admin/dashboard/universe-kams',params),
    downloadExcelUniverseKams: (filters) => axios.post('/admin/dashboard/universe-kams/download-excel',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),

    //Servicios trabajos pendientes
    getGraphsPending: (params) => axios.post('/admin/dashboard/graphs2',params),
}