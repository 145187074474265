<template>
  <div>
    <GraphsMenu menu="kams-supports"></GraphsMenu><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          Promotores / Ciudades (Soporte)
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>      
              <CCol md="3" style="text-align: right;">
                <CButton color="success" size="sm" @click="downloadExcel">Descargar Excel</CButton>
              </CCol>
              <CCol md="4" style="text-align: right;">
                <CSelect class="m-0"
                  :value.sync="monthSelect" 
                  :options="monthsOptions"
                  @change="changeFilters"
                />  
              </CCol>
              <CCol md="5" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="kamSelect" 
                  :options="kamsOptions"
                  @change="changeFilters"
                />   
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="6" class="table-support">
            <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="collaborators"
              :fields="fields"
              :items-per-page="15"
              :dark="false"
              pagination
              style="width: 100%; font-size: 10px; padding: .25rem !important;"
              :table-filter="{label: 'Buscar: ', placeholder: '...'}"
            >
              <template #collaborator_name="{item}"><td>{{item.collaborator_name}}</td></template>
              <template #collaborator_salary="{item}">
                <td class="text-right w50">${{item.collaborator_salary}}</td>
              </template>
              <template #branch_state="{item}">
                <td class="text-center w50">
                  {{item.branch_state}}
                </td>
              </template>
              <template #total_exists="{item}">
                <td class="text-center w50">
                  {{item.total_exists}}
                </td>
              </template>
              <template #acepted="{item}">
                <td class="text-center w50">
                  {{item.acepted}}
                </td>
              </template>
              <template #total_rejected="{item}">
                <td class="text-center w50">                 
                  <template v-if="item.total_rejected > 0">
                    <a href="#" @click="openDetailSupportModal(item.collaborator_id)">{{item.total_rejected}}</a>  
                  </template>
                  <template v-else>
                    {{item.total_rejected}}
                  </template>
                </td>
              </template>
              <template #collaborator_salary_generate="{item}">
                <td class="text-right w50">
                  ${{item.collaborator_salary_generate}}
                </td>
              </template>
              <template #collaborator_salary_extra="{item}">
                <td class="text-right w50" style="align-items: center;">
                  <div class="text-right" style="display: flex; float: right; font-size: 10px; align-items: center;">
                    $
                    <CInput      
                      style="width: 80px; align-items: center; text-align: right;"
                      v-model="item.collaborator_salary_extra"
                      class="mini-font "
                      @change="updateSalaryExtra(item)"
                    />
                  </div>                  
                </td>
              </template>
              <template #collaborator_salary_authorized="{item}">
                <td class="text-right w50">
                  ${{item.collaborator_salary_authorized}}
                </td>
              </template>
              <template #collaborator_bank="{item}">
                <td class="text-center w50">
                  {{item.collaborator_bank}}
                </td>
              </template>
            </CDataTable>
          </CCol>
          <CCol md="6">
              <CRow>           
                <CCol md="4">
                  <CCol md="12" style="text-align: right; padding-right: 0px;">
                    <CSelect class="m-0" style="float: right; width: 100%;"
                      :value.sync="collaboratorSelect" 
                      :options="collaboratorOptions"
                      @change="changeFiltersGraphs"
                    />   
                  </CCol>              
                </CCol>  
                <CCol md="8">
                  <div style="float: right; padding-top: 2px; padding-right: 10px;">
                    <h4>Sueldo Ganado: ${{ metrics.collaborator_salary_generate }}</h4>
                  </div>                  
                </CCol>                       
              </CRow>
              <br>
              <CRow>                           
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.completed_percent)+'%'"
                    text="% De Cumplimiento"
                    color="success"
                    :value="Number(metrics.completed_percent)"
                    >
                    </CWidgetProgress>
                </CCol>  
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.acepted_percent)+'%'"
                    text="% Efectividad Visitas"
                    color="success"
                    :value="Number(metrics.acepted_percent)"
                    >
                    </CWidgetProgress>
                </CCol>
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.collaborator_salary_generate_percent)+'%'"
                    text="% Sueldo Ganado"
                    color="success"
                    :value="Number(metrics.collaborator_salary_generate_percent)"
                    >
                    </CWidgetProgress>
                </CCol>                 
              </CRow>
              <CRow>     
                <CCol md="4">
                  <GaugePercentSemaforoGraph ref="graphPercent" title="% Cumplimiento" ></GaugePercentSemaforoGraph>
                </CCol>                                   
                <CCol md="4">
                  <DonoutSemaforoGraph ref="graphDonout" title="% Efectividad Visitas" isMini="1"></DonoutSemaforoGraph>
                </CCol>  
                <CCol md="4">
                  <GaugeSemaforoGraph ref="graphGauge" title="% Sueldo Ganado" isMini="1"></GaugeSemaforoGraph>
                </CCol>      
              </CRow>
          </CCol>          
        </CRow>
      </CCardBody>
    </CCard>
    <DetailSupportModal ref="detailSupportModal"></DetailSupportModal>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import DonoutSemaforoGraph from './graphs/DonoutSemaforoKams.vue';
import GaugeSemaforoGraph from './graphs/GaugeSemaforo.vue';
import GaugePercentSemaforoGraph from './graphs/GaugePercentSemaforo.vue';
import GraphsMenu from './GraphsMenu.vue';
import DetailSupportModal from './modals/BranchsKamsSopportDetailModal.vue';

export default {
  name: 'GraphsKamsSupports',
  components: {
    DonoutSemaforoGraph,
    GaugeSemaforoGraph,
    GaugePercentSemaforoGraph,
    GraphsMenu,
    DetailSupportModal
  },
  data () {
    return {
      code: 'general',
      collaborators: [],
      fields: [
          {key: 'collaborator_name', label: 'Promotor'},
          {key: 'collaborator_salary', label: 'Sueldo'},
          {key: 'branch_state', label: 'Ruta'},
          {key: 'total_exists', label: 'Total Visitas' ,_style:" width: 50px;"},
          {key: 'acepted', label: 'Visitas Aceptadas', _style:" width: 50px;"},          
          {key: 'total_rejected', label: 'Rechazadas Y Pendientes', _style:" width: 50px;"},
          {key: 'collaborator_salary_generate', label: 'Salario Generado', _style:" width: 50px;"},
          {key: 'collaborator_salary_extra', label: 'Descuento o Bono', _style:" width: 100px;"},
          {key: 'collaborator_salary_authorized', label: 'Autorizado x Pagar', _style:" width: 50px;"},
          {key: 'collaborator_bank', label: 'Cuenta de Banco', _style:" width: 100px;"},
        ],      
      kamSelect: "",
      kamsOptions: [],
      monthSelect: "202409",
      monthsOptions: [],
      collaboratorSelect: "",
      collaboratorOptions: [],
      graphs_gauge: 0,
      semaforo: 0,
      graphs_percent:0,
      graphs_donout:[{code: '',service : "",total : "0",}],
      metrics:{
        completed : "0",
        completed_percent : "0",
        acepted: "0",
        acepted_percent : "0",
        collaborator_salary_generate: "0",
        collaborator_salary_generate_percent: "0",
        collaborator_salary_generate_percent: "0",
      },
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_kam = localStorage.getItem("is_kam");

      if(is_kam == "false"){
        window.location.href = "/#/home"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/welcome"; this.loaded(); return;
      }
    }

    let response = await ws.getKamsSupports(); 
    if(response.type == "success"){
      this.kamsOptions = await this.$parseSelectOptions(response.data, "id", "name",[{"label": "Todos","value":""}]);
      if(response.data.length > 0){
        this.kamSelect = this.kamsOptions[0].value;
      }      
    }

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          collaborator_id: "",
        };
        let responseMetrics = await ws.getMetricsKamsSupports(filters);
        if(responseMetrics.type == "success"){
          this.metrics = responseMetrics.data;
          this.graphs_percent = responseMetrics.data.graph_percent;      
          this.graphs_donout = responseMetrics.data.graph_donout;     
          this.graphs_gauge = responseMetrics.data.graph_guage;
        }
        let response_table = await ws.getTableKamsSupports(filters);
        if(response_table.type == "success"){
          this.collaborators = response_table.data.collaborators;
          this.collaboratorOptions = await this.$parseSelectOptions(this.collaborators, "collaborator_id", "collaborator_name",[{"label": "Todos","value":""}]);
          this.collaboratorSelect = "";
        }
        this.chargeGraphs();
      this.loaded();
    },
    async changeFiltersGraphs () {
      this.loading();
        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          collaborator_id: this.collaboratorSelect,
        };
        let responseMetrics = await ws.getMetricsKamsSupports(filters);
        if(responseMetrics.type == "success"){
          this.metrics = responseMetrics.data;          
          this.graphs_percent = responseMetrics.data.graph_percent;      
          this.graphs_donout = responseMetrics.data.graph_donout;     
          this.graphs_gauge = responseMetrics.data.graph_guage;
        }        
        this.chargeGraphs(); 
      this.loaded();
    },    
    async updateSalaryExtra(item){
      this.loading();
        let params = {
          month : this.monthSelect,
          collaborator_id: item.collaborator_id,
          amount: item.collaborator_salary_extra,
        };
        let response = await ws.updateCollaboratorSalaryExtra(params);

        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          collaborator_id: this.collaboratorSelect,
        };

        let response_table = await ws.getTableKamsSupports(filters);
        if(response_table.type == "success"){
          this.collaborators = response_table.data.collaborators;
        }
        this.changeFiltersGraphs();
        
      this.loaded();
      this.showToast(response.type, response.message);
    },
    async chargeGraphs(){
        await this.$refs.graphPercent.loadGraph(this.graphs_percent);
        await this.$refs.graphDonout.loadGraph(this.graphs_donout);
        await this.$refs.graphGauge.loadGraph(this.graphs_gauge);
    },
    async openDetailSupportModal(collaborator_id){
      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          collaborator_id: collaborator_id,
        };
        await this.$refs.detailSupportModal.showBranchsKamsModal(filters);
    },
    async downloadExcel(){
      this.loading();

      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
        };
      await ws.downloadExcelKamsSupports(filters);
      
      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
.table-support .table th {
  padding: .25rem !important;
}
.table-support .table td {
  padding: .5rem !important;
}
.mini-font .form-control{
  font-size: 10px !important;
  margin-bottom: 0px !important;
  text-align: right;
}
.mini-font.form-group{
  font-size: 10px !important;
  margin-bottom: 0px !important;
}
</style>
