<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
      size="lg"
      class="basic-modal-color"
    > 
    <CRow>      
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="customers"
            :fields="fieldsCustomers"
            :dark="dark"
            style="width: 100%; font-size: 10px;"
          >
            <template #customer_name="{item}">
              <td class="text-center">{{item.customer_name}}</td>
            </template>
            <template #total_universe="{item}">
              <td class="text-center w50">
                {{item.total_universe}}        
              </td>
            </template>
            <template #total_exists="{item}">
              <td class="text-center w50">
                {{item.total_exists}}        
              </td>
            </template>
            <template #x_exists="{item}">
              <td class="text-center w50">
                {{item.x_exists}}        
              </td>
            </template>
            <template #acepted="{item}">
              <td class="text-center w50">
                {{item.acepted}}        
              </td>
            </template>
            <template #completed="{item}">
              <td class="text-center w50">
                {{item.completed}}        
              </td>
            </template>
            <template #pending="{item}">
              <td class="text-center w50">
                {{item.pending}}        
              </td>
            </template>            
          </CDataTable>        
        </CCol>
      </CRow>    
      <CRow>      
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="branchs"
            :fields="fields"
            :dark="dark"
            :items-per-page="15"
            pagination
            style="width: 100%; font-size: 10px;"
            :table-filter="{label: 'Buscar: ', placeholder: '...'}"
          >
            <template #branch_code="{item}"><td>{{item.branch_code}}</td></template>
            <template #branch_name="{item}"><td>{{item.branch_name}}</td></template>
            <template #x_exists="{item}">
              <td class="text-center w75">
                {{item.x_exists}}
              </td>
            </template>
            <template #x_completed="{item}">
              <td class="text-center w75">
                {{item.x_completed}}
              </td>
            </template>
            <template #x_review="{item}">
              <td class="text-center w75">
                {{item.x_review}}
              </td>
            </template>
            <template #rejected="{item}">
              <td class="text-center w75">
                {{item.rejected}}
              </td>
            </template>        
            <template #never_completed="{item}">
              <td class="text-center w50">
                {{item.never_completed}}        
              </td>
            </template>      
          </CDataTable>        
        </CCol>
      </CRow>      
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../../store';
import ws from '../../../services/graphs';

export default {
  name: 'BranchsKamsModal',
  props: {
    fields: {
      type: Array,
      default () {
        return [
          {key: 'branch_code', label: 'Determinante' ,_style:" width: 100px;"},
          {key: 'branch_name', label: 'Sucursal'},          
          {key: 'x_completed', label: 'X Realizar',_style:" width: 75px;"},
          {key: 'x_review', label: 'X Revisar', _style:" width: 75px;"},
          {key: 'rejected', label: 'Rechazadas', _style:" width: 75px;"},
        ]
      }
    },
    fieldsCustomers: {
      type: Array,
      default () {
        return [
          {key: 'customer_name', label: 'Marca'},
          {key: 'total_universe', label: 'Total Universo' ,_style:" width: 100px;"},
          {key: 'total_exists', label: 'Total Programadas' ,_style:" width: 100px;"},
          {key: 'x_exists', label: 'X Programar',_style:" width: 100px;"},
          {key: 'completed', label: 'Realizadas', _style:" width: 75px;"},
          {key: 'acepted', label: 'Aceptadas', _style:" width: 75px;"},
          {key: 'pending', label: 'Pendientes', _style:" width: 75px;"},
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  components: {
  },
  data () {
    return {
      showModal: false,
      title: "Detalle Marca",
      color: "info",
      submitted: false,      
      branchs: [],
      customers: [],
    }
  },
  mounted: async function() {    
    
  },
  methods: {
    async showBranchsKamsModal (params) {
      this.loading();
        this.showModal = true;
        this.color = "info";
        this.title = 'Detalle Marca';
        this.isEdit = false;
        this.submitted = false;
      
        let response = await ws.detailKamsBoss(params);
        if(response.type == "success"){
          this.branchs = response.data.branchs;
          this.customers = response.data.customer;
        }
      this.loaded();

    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
    },
    getEmptyForm () {
      return {
          id: 0,
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
}
</script>